import React from "react";
import { useStyletron } from "baseui";
import { expandBaseWebBorder, expandBorderRadii } from "../utils";

export const SearchSlashIcon = () => {
  const [css, $theme] = useStyletron();
  return (
    <span
      className={css({
        ...$theme.typography.LabelSmall,
        color: $theme.colors.primary700,
        backgroundColor: $theme.colors.backgroundSecondary,
        ...expandBorderRadii($theme.borders.radius200),
        ...expandBaseWebBorder($theme.borders.border200),
        boxShadow: $theme.lighting.shadow400,
        marginLeft: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: $theme.sizing.scale800,
        width: $theme.sizing.scale800,
      })}
    >
      <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.589 0.189941H7.297L2.411 11.8099H0.703003L5.589 0.189941Z" fill="black" />
      </svg>
    </span>
  );
};
